import React, { useState } from 'react';
import { Link } from "gatsby"; 
import { CSSTransition } from 'react-transition-group';

import LayoutBase from "../components/layoutBase"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import './index.css';

function HomePage({ videos }) {
  const [bgFade] = useState(true);

  return (
    <LayoutBase>
      <Seo title="Home" />

    <div>
      <div id="homepage">
        <CSSTransition in={bgFade} appear={bgFade} timeout={5000} classNames="bg-fade">
          <div id="header">
            <div className="inner">
            <header>
              <h1>Knockout Cats</h1>
              <hr />
              <p>It's time to fight, ladies.<br/>Kick her ass, or get knocked out.</p>
            </header>
            <footer>
              <Link to="/videos" className="button">Fight!</Link>
            </footer>
            </div>
          </div>
        </CSSTransition>
      </div>

      <div className="wrapper style2">
        <article id="main" className="container restrain-in-page">

          <section id="banner">
            <header>
              <h2>Knockout Cats News</h2>
              February 2022
              <p>
                We've finally released the new website!
              </p>
            </header>
          </section>

          <div className="center">
            <StaticImage
              src="../images/knockoutcats_title.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Knockout Cats"
            />
          </div>

          <p>
            Hello, friend.
          </p>

          <p>
            For those visiting for the first time, welcome.
          </p>

          <p>
            For those who have waited patiently for the return of the site,
            I apologize for the delay. I know it was a lengthy hiatus, but {"I'm"} glad to
            see {"you've"} returned.
          </p>

          <p>
            There are still several improvements that I plan to make over time, but for now,
            here are the current features that visitors might be interested in:
          </p>

            <ul>
              <li>
                All past <Link to="/videos">videos</Link> can once again be purchased directly from the site.
              </li>

              <li>
                A new video (previously unreleased to the public) is now
                available for purchase:
                <ul>
                  <li><Link to="/videos/koc0021-morgan-nikkinext-2">Morgan Del Ray vs. Nikki Next Fight 2</Link></li>
                </ul>
              </li>

              <li>
                A listing of the beautiful Knockout Cat fighters can be found on the <Link to="/fighters">fighters</Link> page.
              </li>

              <li>
                A new <Link to="/blog">blog</Link> has been released with:
                <ul>
                  <li>
A few <Link to="/blog/site-guidelines">site guidelines</Link> (please read and abide)
                  </li>

                  <li>Some additional bonus content (more in the future)</li>
                </ul>
              </li>
            </ul>

           <p>
Check back for updates, new videos, and bonus blog content.
           </p>
           <p>
Enjoy the new site!
           </p>

         </article>
      </div>
    </div>
    </LayoutBase>
  );
}

export default HomePage
